import InvestidorGateway from "./investidorGateway";
import api from '../../services/Api'
import { CaptacaoInvestidorDetail, ISolicitacaoCaptacao } from "@/Types/Captacao";
import { BASE_URL } from "../../env/variables";
import { IDadosPessoais, InvestidorDetail } from "@/Types/Investidor";
import {AvaliacaoInvestimento} from '@/Types/Investimento';
import { Socio, SocioDetailBase, SocioTipo } from "@/Types/Socio";
import { Chats, MensagemChat } from '@/Types/MensagemChat';

const SERVICE_URL = `${BASE_URL}api/investidor/`

export default class InvestidorGatewayHttps implements InvestidorGateway {

    async getSocios(): Promise<Socio[] | void> {
        const response = await api.get<Socio[]>(`${SERVICE_URL}socios`);
        return response;
    }

    async getSocioDetail<Type extends SocioDetailBase>(id: number, tipo: SocioTipo): Promise<Type | void> {
        const response = await api.get<Type>(`${SERVICE_URL}socio-detail/${tipo}/${id}`)
        return response;
    }
    
    async getInvestidoresJuridicos(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}minhas-contas/`);
        return response;
    }

    async getInvestimentos(): Promise<any> { 
        const response = await api.get<any>(`${SERVICE_URL}investimentos/`);
        return response;
    }

    async getResultadoInvestimentos(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}resultados-investimentos/`);
        return response;
    }

    async getInfoCalendario(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}calendario-recebimentos/`);
        return response;
    }
    async getChartsData(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}charts-data/`);
        return response;
    }
    async getCaptacaoDetail(id: number): Promise<CaptacaoInvestidorDetail> {
        const response = await api.get<any>(`${SERVICE_URL}captacao-detail/${id}/`);
        return response

    }
    async getCaptacoesAbertasPainelInvestidor(): Promise<any> {
        const response = await api.get<any>(`${BASE_URL}data/captacoes-abertas-painel-investidor`);
        return response;
    }

    async getInvestidorDetail(): Promise<InvestidorDetail> {
        const response = await api.get<any>(`${SERVICE_URL}investidor-detail/`);
        return response;
    }

    async getTabelaVendas(id: number): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}export-tabela-vendas/${id}/`, 'blob');
        return response;
    }

    async getTabelaExtrato(id: number): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}export-tabela-extrato/${id}/`, 'blob');
        return response;
    }

    async getMovimentacoes(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}carteira/movimentacoes/`);
        return response;
    }

    async getContaCarteiraDetail(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}carteira/detail/`);
        return response;
    }

    async getMovimentacoesCarteira(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}carteira/movimentacoes/exportar/`,'blob');
        return response;
    }

    async getDadosPessoais(): Promise<IDadosPessoais> {
        const response = await api.get<any>(`${SERVICE_URL}dados-pessoais/`);
        return response;
    }

    async getDadosEndereco(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}endereco/`);
        return response;
    }

    async getDadosDocumentos(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}documentos/`);
        return response;
    }

    async getDadosInvestidorJuridico(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}investidor-juridico/`);
        return response;
    }

    async getListaArquivos(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}lista-arquivos/`);
        return response;
    }

    async getPix(id: number): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}pix-do-investimento/${id}/`);
        return response;
    }

    async getInformacoesInvalidas(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}informacoes-invalidas/`);
        return response;
    }

    async getUnidades(captacao_id: number): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}tabelas-unidades/${captacao_id}/`);
        return response;
    }

    async getInformacoesInvestimento(projeto_slug: string, captacao_slug: string, incorporadora_slug: string){
        const response = await api.get<any>(`${BASE_URL}api/investimento/geral/${incorporadora_slug}/${projeto_slug}/${captacao_slug}/`);
        return response;
    }
    

    async getParcelas(reserva_id: number): Promise<any>{
        const response = await api.get<any>(`${SERVICE_URL}captacao-externa/tabela-parcelas/${reserva_id}/list/`);
        return response
    }

    async getDownloadProspecto(captacao_id: number): Promise<any> {
        const response = await api.get<any>(`${BASE_URL}export_prospecto_oferta/${captacao_id}/`,'blob');
        return response;
    }

    async getDownloadPlanilhaPadrao(): Promise<any> { 
        const response = await api.get<any>(`${SERVICE_URL}captacao-externa/planilha-parcelas-modelo/`, 'blob')
        return response;

    }

    async getAcessos(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}get-acessos`);
        return response
    }

    async getInformacoesTermos(): Promise<any> {
        const response = await api.get<any>(`${BASE_URL}api/investimento/termos/`);
        return response;
    }

    async getInformacoesAutenticacao(): Promise<any> {
        const response = await api.get<any>(`${BASE_URL}api/investimento/autenticacao/`);
        return response;
    }

    async getInformacoesAssinatura(): Promise<any> {
        const response = await api.get<any>(`${BASE_URL}api/investimento/assinatura/`);
        return response;
    }

    async getInformacoesPagamento(): Promise<any> {
        const response = await api.get<any>(`${BASE_URL}api/investimento/pagamento/`);
        return response;
    }

    async getInformacoesFinalizar(): Promise<any> {
        const response = await api.get<any>(`${BASE_URL}api/investimento/finalizar/`);
        return response;
    }

    async getOfertas(): Promise<any> {
        const response = await api.get<any>(`${BASE_URL}api/investidor/marketplace/`);
        return response;
    }

    async getOperadores(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}operadores`)    
        return response;
    }

    async getRemoverContaUrbe(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}remover-conta-urbe`)
        return response;
    }

    async getChats(): Promise<Chats> {
        const response = await api.get<any>(`${SERVICE_URL}list-chat`)
        return response;
    }

    async getMensagensChat(id: number): Promise<MensagemChat> {
        const response = await api.get<any>(`${SERVICE_URL}list-mensagem-chat/${id}`)
        return response;
    }

    async getProjetosInvestidos():Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}projetos-investidos/`)
        return response;
    }

    async getAssuntoETopicoChat():Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}assuntos-topicos/`)
        return response;
    }

    async getFAQChat():Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}faq-chat/`)
        return response;
    }

    async getIncorporadoras(): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}lista/incorporadoras/`)
        return response
    }

    async getCaptacaoExternaDetail(reserva_id: Number): Promise<any> {
        const response = await api.get<any>(`${SERVICE_URL}captacao-externa/${reserva_id}/detail/`)
        return response
    }

    //POST
    async postEditParcela(body: FormData): Promise<any> {
        const response = await api.post<any>(body,`${SERVICE_URL}parcela/update/`);
        return response;
    }

    async postExcluirReserva(reserva_id: Number): Promise<any> {
        const response = await api.post<any>({}, `${SERVICE_URL}captacao-externa/excluir/${reserva_id}/`);
        return response
    }
  
    async postTabelaParcelas(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}captacao-externa/tabela-parcelas/create/`);
        return response;
    }

    async postSPEData(body: JSON): Promise<any> {
        const response = await api.post<any>(body,`${SERVICE_URL}dados-spe/`);
        return response;
    }
    
    async postCaptacaoExternaCreate(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}captacao-externa/create/`)
        return response;
    }

    async postNovoChat(body: FormData): Promise<any> {
        const response = await api.post<any>(body,`${SERVICE_URL}novo-chat/`)   
        return response;
    }
    async removerChat(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}remover-chat/`);
        return response;
    }

    async postMensagemChat(body: FormData): Promise<any> {
        const response = await api.post<any>(body,`${SERVICE_URL}nova-mensagem-chat/`)   
        return response;
    }

    async postDadosContaBancaria(body: FormData): Promise<any> {
        const response = await api.post<any>(body,`${SERVICE_URL}conta-bancaria/update/`)   
        return response;
    }

    async postResgate(body: JSON): Promise<any> {
        const response = await api.post<any>(body,`${SERVICE_URL}resgatar/`);        
        return response;
    }

    async postUpdateDadosPessoais(body: FormData): Promise<any> {
        const response = await api.post<any>(body,`${SERVICE_URL}dados-pessoais/update/`);   
        return response;
    }

    async postUpdateDadosDocumentos(body: FormData): Promise<any> {
        const response = await api.post<any>(body,`${SERVICE_URL}documentos/update/`);   
        return response;
    }

    async postUpdateDadosEndereco(body: FormData): Promise<any> {
        const response = await api.post<any>(body,`${SERVICE_URL}endereco/update/`); 
        return response;
    }

    async postUpdateInvestidorJuridico(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}investidor-juridico/update/`);
        return response;
    }

    async postUpdateSenha(body: FormData): Promise<any> {
        const response = await api.post<any>(body,`${SERVICE_URL}trocar-senha/`);
        return response;
    }
    
    async postRedefSenha(body: FormData): Promise<any> {
        const response = await api.post<any>(body,`${SERVICE_URL}redefinir-senha/`);
        return response;
    }

    async postRedefinirSenhaHome(body: FormData): Promise<any> {
        const response = await api.post<any>(body,`${BASE_URL}esqueceu-a-senha/`);
        return response;
    }

    async postAvaliacao(body: AvaliacaoInvestimento): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}avaliacao/incorporadora/`);
        return response;
    }

    async postCadastroInvestidor(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${BASE_URL}cadastro/`);
        return response;
    }

    async postCadastroInvestidorPJ(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${BASE_URL}cadastro-pj/`);
        return response;
    }

    async postLogin(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${BASE_URL}api/login/`);
        return response;
    }

    async post2fa(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${BASE_URL}api/2fa/`);
        return response    
    }

    async postGerarCCBSocinal(method: string): Promise<any> {
        const response = await api.post<any>(JSON.stringify({signature : method}), `${BASE_URL}investimento/gerar_ccb_socinal/`, 'application/json');
        return response;
    }

    async postGerarPix(): Promise<any> {
        const response = await api.post<any>({}, `${BASE_URL}investimento/gerar_pix/`);
        return response;
    }

    async postGerarPixEstatico(): Promise<any> {
        const response = await api.post<any>({}, `${BASE_URL}api/investidor/pix-da-carteira/`);
        return response;
    }

    async postNovoOperador(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}criar-operador/`);
        return response;
    }

    async removerOperador(body: { operador_id: number; }): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}remover-operador/`);
        return response;
    }

    async updateOperador(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}atualizar-operador/`);
        return response;
    }

    //PASSOS INVESTIMENTO

    async postValoresInvestimento(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${BASE_URL}api/investimento/passo/valores/`);
        return response;
    }

    async postTermosInvestimento(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${BASE_URL}api/investimento/passo/termos/`);
        return response;
    }
    
    async postAutenticacaoInvestimento(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${BASE_URL}api/investimento/passo/autenticacao/`);
        return response;
    }

    async postAssinaturaInvestimento(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${BASE_URL}api/investimento/passo/assinatura/`);
        return response;
    }

    async postPagamentoInvestimento(): Promise<any> {
        const response = await api.post<any>({}, `${BASE_URL}api/investimento/passo/pagamento/`);
        return response;
    }

    async postNovoSocioPF(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}novo-socio-pf/`);
        return response;
    }

    async updateSocioPF(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}update-socio-pf/`);
        return response;
    }

    async postNovoSocioPJ(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}novo-socio-pj/`);
        return response;
    }

    async updateSocioPJ(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}update-socio-pj/`);
        return response;
    }

    async postRemoveSocio(body: { base_socio: number; investidor_juridico: boolean; socio: string; }): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}remove-socio/`);
        return response;
    }

    async postImportSocio(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}import-socio/`);
        return response;
    }

    async postUpdateInvestidorJuridicoSociedade(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}atualizar-sociedade/`)
        return response;
    }

    async postNovaProposta(body: FormData): Promise<any> {
        const response = await api.post<any>(body, `${SERVICE_URL}nova-proposta/`)
        return response;
    }
}