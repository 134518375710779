<script>
import logoWhite from '@/assets/u-logo-white.png';
import openMenuIcon from '@/assets/open_mobile_menu.svg';
import closeIcon from '@/assets/cross.svg'
export default {
    name: "MobileHeader",
    props:{
        mobileMenu: {type: Boolean, default: false},
        isHome: {type: Boolean, default: false},
    },
    data() {
        return {
            logoWhite,
            openMenuIcon,
            closeIcon
        }
    },

    emits: ['setMobileMenu'],
}
</script>

<template>
    <header class="flex justify-between w-full h-[6rem] shadow-[0_5px_10px_rgba(231,231,231,1)]"
        :class="{
            'mobile-grey-bg': !isHome,
            'white-bg': isHome,
    }">
        <a href="/" class="flex justify-between h-full items-center filter-dark-blue mx-[1.5rem] w-[4.1rem]">
            <img :src="logoWhite" />
        </a>
        <div @click="$emit('setMobileMenu')" id="open_mobile_menu" class="flex justify-center items-center px-[1.5rem] w-[4.5rem] cursor-pointer">
            <img v-if="!mobileMenu" :src="openMenuIcon" />
            <p v-else class="font-bold">x</p>
        </div>
    </header>
</template>

