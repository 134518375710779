<script lang="ts">
import { defineComponent } from 'vue';
import { authentication } from '@/services/Authentication';
import InputField from './InputField.vue';
import { InputType } from '@/Types/InputTypes';

export default defineComponent({
    name: "Hijacker",

    components: {

        InputField,
    
    },

    data() {
        return {
            isOpened: false,
            admin: false,
            userAccess: undefined as Object | undefined,
            
            draging: false,
            draggingOffset: 0,
            height: this.getHeight(),
            hijackEmail: '',
            emailHijack: '',
            visible: false,
            InputType,
        }
    },
    methods: {
        setHeight(height: number) {
            this.height = height;
            localStorage.setItem("hijackHeight", String(height));
        },
        getHeight() {
            return Number(localStorage.getItem("hijackHeight")) || 200
        },
        toggleHijackDetail() {
            this.isOpened = !this.isOpened;
        },
        toggleVisibility() {
            this.setVisibility(!this.visible);
        },
        handleClickUp() {
            if (!this.draging) {
                this.toggleHijackDetail();
            }
            document.onmouseup = null;
            document.onmousemove = null;
            this.draging = false;
        },
        handleMouseClick(event: MouseEvent) {
            const clientY = event.clientY;
            const offset = Number(clientY - this.height);
            this.draggingOffset = offset;
            document.onmousemove = this.handleMouseMove
            document.onmouseup = () => this.handleClickUp();
        },
        handleMouseMove(event: MouseEvent) {
            this.draging = true;
            const height = event.clientY - this.draggingOffset;
            const maxHeight = window.screen.availHeight;
            if (height + 150 < maxHeight && height > 0)
                this.setHeight(height);
        },
        getHijackData() {
            this.canHijack()
            window.addEventListener('authentication-update', (event: any) => {
                this.canHijack()
            })
            if (this.visible) {
                window.addEventListener('keydown', (event: KeyboardEvent) => {
                    if(event.key == 'h' && document.activeElement?.tagName.toLowerCase() != 'input') {
                        this.toggleVisibility();
                    }
                })
            }
        },
        canHijack() {
            const userAccess = authentication.getUserAccess();
            if(!userAccess) return;
            this.admin = Boolean(userAccess.admin);
            this.hijackEmail = userAccess.hijacked;
            this.setVisibility(this.admin || Boolean(this.hijackEmail))
        },
        setVisibility(isVisible: boolean) {
            this.visible = isVisible;
        }
    },
    mounted() {
        this.getHijackData();
    },
})

</script>

<template>
    <!-- VISIBLE IF IS ADMIN -->
    <div v-if="visible"
        class="fixed right-0 dark-blue-bg light-shadow text-white rounded-l-3xl z-[9999999]"
        :style="{top: `${height}px`}">

        <template
            v-if="isOpened"
        >
            <div
                v-if="hijackEmail"
                class="p-6">
                <div class="w-full flex justify-end mb-2" @click="isOpened = false">
                    <i class="fal fa-times text-white text-[2rem] cursor-pointer"></i>
                </div>
                <p>Logado em nome de <span class="font-bold">{{ hijackEmail || emailHijack }}</span></p>
                <div class="flex justify-end items-center w-full">
                    <div class="mt-6 w-fit flex justify-end bg-white dark-blue border-main">
                        <a href="/hijack/release/" class="p-2">Liberar</a>
                    </div>
                </div> 
            </div>
            <div 
                v-else
                class="p-6">
                <div class="w-full flex justify-end mb-2" @click="isOpened = false">
                    <i class="fal fa-times text-[2rem] cursor-pointer"></i>
                </div>
                <div>
                    <div class="col col-span-2">
                        <div class="flex justify-end items-center gap-x-5">
                            <div class="flex-grow w-[25rem] dark-blue">
                                <InputField v-model:value="emailHijack" name="email-hijack" label="E-mail para o hijack:" placeholder="E-mail" :type="InputType.TEXT"/>
                            </div>
                            <div class="bg-white dark-blue mt-[2.3rem] border-main">
                                <a :href="`/hijack/email/${emailHijack}`" class="p-2">Hijack</a> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </template>
        <div
            v-else
            ref="draggable"
            @click.right.prevent="toggleVisibility"
            @mousedown.left="handleMouseClick"
            class="py-1 px-6 text-[3rem] font-bold cursor-pointer select-none">
            H
        </div>

    </div>
</template>